@import url("https://fonts.googleapis.com/css?family=Tinos&display=swap");

html,
body {
  margin: 0px;
  font-family: Tinos, serif;
}
:root {
  --splash-height: 440px;
}
.splash {
  height: var(--splash-height);
  transition: height 1s;
  position: relative;
}
.blur {
  background-image: url(/figure.jpg);
  position: absolute;
  width: 100vw;
  height: var(--splash-height);
  transition: height 1s;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(2px);
}
.gold {
  background: #af8c18;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 10px;
  height: 400px;
}
.wordmark {
  position: absolute;
  top: 20px;
  right: 40px;
  width: min(820px, 75vw);
}
.wordmark .red {
  width: min(240px, 31vw);
  margin-top: min(21px, 2vw);
  float: right;
}
.text {
  padding: 20px;
  margin: 0 auto;
  max-width: 700px;
}
h1 {
  font-size: 28px;
}
p {
  font-size: 21px;
}
img.full {
  width: 100vw;
  display: block;
  max-height: 600px;
  object-fit: cover;
}

.menu-item span.price,
.menu-item .price b,
h2,
a {
  color: #cd1719;
}
h2 {
  font-style: italic;
  font-size: 24px;
  margin-top: 2em;
  margin-bottom: 0px;
}
h1 + h2,
h1 + a + h2 {
  margin-top: 0px;
}
.menu-title {
  margin-bottom: 0px;
}
.menu-title + a {
  font-size: 21px;
}
.menu-item {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto;
}
.menu-item + .menu-item {
  margin-top: 20px;
}
.menu-item h3,
.menu-item p {
  margin: 0px;
  font-size: 21px;
}
.menu-item h3 {
  grid-row: 1;
  grid-column: 1;
}
.menu-item p {
  grid-row: 2;
  grid-column: 1;
  font-size: 18px;
  margin-right: 10px;
}
.menu-item span.price,
.menu-item .price b {
  font-size: 21px;
  font-weight: bold;
}
.menu-item span.price {
  grid-row: 1;
  grid-column: 2;
}
.menu-item.price-variants p {
  grid-column: 1;
}

.menu-item.price-variants .price {
  grid-row: 1/3;
  text-align: right;
}
.menu-item.price-variants .price i {
  margin-right: 10px;
}

@media screen and (max-width: 600px) {
  .menu-item,
  .menu-item.price-variants {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
  }
  .menu-item.price-variants .price,
  .menu-item span.price {
    grid-row: 3;
    grid-column: 1;
  }
  .menu-item span.price {
    text-align: right;
  }
  .menu-item.price-variants p {
    grid-column: 1;
  }
}
